<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <div class="grid grid-cols-12 mb-4">
      <div class="col-span-12 md:col-span-4 2xl:col-span-3">
        <label class="block text-lg font-medium">Configurar Tabulações / Etiquetas:</label>
        <router-link
          :to="`/tag`"
          type="button"
          class="mb-2 mt-2 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
        >
          <span class="inline-block mr-2">Tabulações / Etiquetas</span>
        </router-link>
      </div>
    </div>
    <div class="grid grid-cols-12">
      <div class="col-span-12 md:col-span-4">
        <label class="block text-lg font-medium"
          >Notificar quando campanhas e validações forem concluídas:</label
        >
        <input
          v-model="form.emailNotification"
          type="email"
          placeholder="Email"
          class="mt-1 focus:ring-indigo-500 mb-4 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>
    <div class="grid grid-cols-12">
      <div class="col-span-12 md:col-span-3">
        <label class="block text-lg font-medium mb-2">Cor principal:</label>
        <sketch-picker v-model="form.colorPrincipal" />
      </div>
      <div class="col-span-12 md:col-span-4">
        <label class="block text-lg font-medium">Logo:</label>
        <small class="text-xs font-medium">Tamanho recomendado 400x200, PNG sem fundo</small>
        <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" @vdropzone-sending="send" v-on:vdropzone-success="update"></vue-dropzone>
        <img class="h-16 mt-4" v-if="this.form.logo && this.form.logo.filename" :src="`${this.api}/upload?mimetype=image/jpeg&filename=${this.form.logo.filename}&folder=arquivosWhats`"/>
        <button
          v-if="this.form.logo && this.form.logo.filename"
          @click="form.logo = null"
          type="button"
          class="bg-red-500 hover:bg-red-600 focus:bg-red-700 focus:shadow-sm mx-2 mt-2 focus:ring-opacity-50 text-white py-1 px-2 rounded-md text-sm shadow-sm hover:shadow-md font-semibold text-center"
        >
          Remover
        </button>
      </div>
    </div>
    <h1 class="text-2xl font-semibold my-3">Limitação de horários</h1>
    <label for="limitarHorariolzv2" class="block text-sm font-medium mb-3">
      <input
        type="checkbox"
        v-model="form.limitarHorariolzv2"
        class="rounded-sm"
        id="limitarHorariolzv2"
      />
      <span class="ml-2"> Limitar horário envio de campanhas e novas conversas </span>
    </label>
    <div v-if="form.limitarHorariolzv2" class="grid grid-cols-12 gap-4">
      <div class="col-span-12 md:col-span-5 2xl:col-span-4">
        <h3 class="text-lg font-semibold mb-4">Dias da semana (segunda a sexta)</h3>
        <h3 class="text-base my-2">Campanhas e Validação</h3>
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-6">
            <label class="block text-sm font-medium">Início</label>
            <input
              v-model="form.horario_campanhalzv2.semana_inicio"
              type="time"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div class="col-span-6">
            <label class="block text-sm font-medium">Fim</label>
            <input
              v-model="form.horario_campanhalzv2.semana_fim"
              type="time"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
        <hr class="my-4" />
        <h3 class="text-base my-2">Novas conversas</h3>
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-6">
            <label class="block text-sm font-medium">Início</label>
            <input
              v-model="form.horario_chatlzv2.semana_inicio"
              type="time"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div class="col-span-6">
            <label class="block text-sm font-medium">Fim</label>
            <input
              v-model="form.horario_chatlzv2.semana_fim"
              type="time"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
      </div>
      <div class="col-span-12 md:col-span-5 2xl:col-span-4">
        <h3 class="text-lg font-semibold mb-4">Final de semana (sábado e domingo)</h3>
        <h3 class="text-base my-2">Campanhas e Validação</h3>
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-6">
            <label class="block text-sm font-medium">Início</label>
            <input
              v-model="form.horario_campanhalzv2.fim_semana_inicio"
              type="time"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div class="col-span-6">
            <label class="block text-sm font-medium">Fim</label>
            <input
              v-model="form.horario_campanhalzv2.fim_semana_fim"
              type="time"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
        <hr class="my-4" />
        <h3 class="text-base my-2">Novas conversas</h3>
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-6">
            <label class="block text-sm font-medium">Início</label>
            <input
              v-model="form.horario_chatlzv2.fim_semana_inicio"
              type="time"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <div class="col-span-6">
            <label class="block text-sm font-medium">Fim</label>
            <input
              v-model="form.horario_chatlzv2.fim_semana_fim"
              type="time"
              class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
      </div>
      
    </div>
    <hr class="my-6" />
    <h1 class="text-xl font-semibold mb-3">Configuração Limite Mensal da Conta</h1>
    <div class="grid grid-cols-12 gap-4 mb-6">
      <div class="col-span-3">
        <label for="limiteMensal" class="block text-sm font-medium mb-3">
          <input
            type="checkbox"
            v-model="form.limiteMensal.ativo"
            class="rounded-sm"
            id="limiteMensal"
          />
          <span class="ml-2"> Limite de envio mensal </span>
        </label>
        <div v-if="form.limiteMensal.ativo">
          <label for="volumeLimite" class="block text-sm font-medium">Volume máximo</label>
          <input
            v-model="form.limiteMensal.volume"
            type="number"
            name="volumeLimite"
            id="volumeLimite"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
      </div>
    </div>
    <hr v-if="$store.state.user.lzv2.cliente_novavida"  class="my-6" />
    <h1 v-if="$store.state.user.lzv2.cliente_novavida" class="text-xl font-semibold mb-3">Configuração de Saldo de Crédito</h1>
    <div v-if="$store.state.user.lzv2.cliente_novavida" class="grid grid-cols-12 gap-4 mb-6">
      <div class="col-span-3">
        <label for="saldoPrePago" class="block text-sm font-medium mb-3">
          <input
            type="checkbox"
            v-model="form.saldoPrePago.ativo"
            class="rounded-sm"
            id="saldoPrePago"
          />
          <span class="ml-2"> Saldo de Envio</span>
        </label>
        <div v-if="form.saldoPrePago.ativo">
          <label for="credito" class="block text-sm font-medium">Saldo</label>
          <input
            v-model="form.saldoPrePago.credito"
            type="number"
            name="credito"
            id="credito"
            class="mt-1 mb-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
          <router-link to="/saldo" class="block underline text-sm text-blue-600 font-medium">Acesse o histórico do saldo</router-link>
        </div>
      </div>
    </div>
    <hr class="my-6" />
    <h1 class="text-xl font-semibold">Configuração de resposta automática</h1>
    <small class="block text-sm font-medium text-red-500 mt-1">Atenção: Com esta configuração o sistema responderá automaticamente a interação do cliente.<br>Enviará uma resposta automática novamente após 10 minutos de uma nova mensagem do cliente.</small>
    <div class="grid grid-cols-12 gap-4 mb-6 mt-3">
      <div class="col-span-3">
        <label for="mensagemAutomaticaAtivo" class="block text-sm font-medium mb-3">
          <input
            type="checkbox"
            v-model="form.mensagemAutomatica.ativo"
            class="rounded-sm"
            id="mensagemAutomaticaAtivo"
          />
          <span class="ml-2"> Ativar a resposta automática </span>
        </label>
        <div v-if="form.mensagemAutomatica.ativo">
          <label for="mensagemAutomatica" class="block text-sm font-medium">Mensagem</label>
          <textarea
            rows="3"
            v-model="form.mensagemAutomatica.mensagem"
            type="text"
            name="mensagemAutomatica"
            id="mensagemAutomatica"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          ></textarea>
        </div>
      </div>
    </div>
    <hr class="my-6" />
    <div class="grid grid-cols-12 gap-4 mb-2">
      <div class="col-span-4">
        <h1 class="text-xl font-semibold mb-3">Token integração</h1>
        <textarea rows="3" v-model="token" class="w-full"></textarea>
      </div>
    </div>
    <div class="grid grid-cols-12 mb-6">
      <div class="col-span-4">
        <label class="block text-sm font-medium">ID Custom (ID INTERNO)</label>
        <input
          v-model="form.idCustom"
          type="text"
          class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>
    <button
      @click="save"
      type="button"
      class="inline-flex justify-center mt-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
    >
      Salvar configuração
    </button>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumbs.vue";
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { Sketch } from 'vue-color';

export default {
  components: {
    vueDropzone,
    Breadcrumb,
    'sketch-picker': Sketch
  },
  data() {
    return {
      breadcrumb: [{ url: "/config", value: "Configuração" }],
      token: '',
      form: {
        emailNotification: "",
        limitarHorariolzv2: false,
        colorPrincipal: '#2F4F4F',
        logo: null,
        horario_campanhalzv2: {
          semana_inicio: "04:00",
          semana_fim: "17:00",
          fim_semana_inicio: "04:00",
          fim_semana_fim: "16:00",
        },
        horario_chatlzv2: {
          semana_inicio: "04:00",
          semana_fim: "23:00",
          fim_semana_inicio: "04:00",
          fim_semana_fim: "17:00",
        },
        limiteMensal: {
          ativo: false,
          volume: 0,
        },
        saldoPrePago: {
          ativo: false,
          credito: 0
        },
        mensagemAutomatica: {
          ativo: false,
          mensagem: ''
        },
        headerOutput: { type: String, default: 'Conseguimos uma ótima alternativa para seu contrato conosco 🥳' },
        footerOutput: { type: String, default: 'Podemos seguir com esta *proposta?*' },
      },
      exemploOutput: ``,
      dropzoneOptions: this.$http.getDropzoneConfig(
          `upload`,
          'post',
      {
          maxFiles: 1,
          addRemoveLinks: true,
          capture: true,
          dictDefaultMessage: 'Clique ou arraste sua logo aqui',
          dictRemoveFile: 'Remover'
      }),
    };
  },
  watch: {
    'form.headerOutput' (header) {
      this.exemploOutput = `${header}\n\n*Olá mensagem enviada por campanha ou conversa.*\n\n${this.form.footerOutput}`;
    },
    'form.footerOutput' (footer) {
      this.exemploOutput = `${this.form.headerOutput}\n\n*Olá mensagem enviada por campanha ou conversa.*\n\n${footer}`;
    }
  },
  methods: {
    send(file, xhr, formData) {
      formData.append('folder', 'arquivosWhats');
    },
    update(file, response) {
      this.form.logo = response.file;
    },
    async save() {
      this.form._id = this.$store.state.user.operador ? this.$store.state.user.usuario : this.$store.state.user._id;

      this.form.colorPrincipal = this.form.colorPrincipal.hex ? this.form.colorPrincipal.hex: this.form.colorPrincipal;

      if (!Number.isInteger(Number(this.form.saldoPrePago.credito))) {
        return this.$vToastify.error("Por favor, insira apenas valores inteiros no saldo!");
      }

      this.form.saldoPrePago.credito = parseInt(this.form.saldoPrePago.credito);

      const resp = await this.$http.put(`/v1/users`, this.form);
      if (resp.data.success) {
        this.$store.commit('setConfig', { colorPrincipal: this.form.colorPrincipal, logo: this.form.logo });
        this.start();
        return this.$vToastify.success("Configuração salva");
      }
      return this.$vToastify.error(resp.data.err);
    },
    async start() {
      const resp = await this.$http.get(`/v1/users/${this.$store.state.user.operador ?this.$store.state.user.usuario : this.$store.state.user._id}`);
      this.form = {
        emailNotification: resp.data.emailNotification,
        limitarHorariolzv2: resp.data.limitarHorariolzv2,
        horario_chatlzv2: resp.data.horario_chatlzv2,
        horario_campanhalzv2: resp.data.horario_campanhalzv2,
        colorPrincipal: resp.data.colorPrincipal,
        limiteMensal: resp.data.limiteMensal,
        saldoPrePago: resp.data.saldoPrePago,
        mensagemAutomatica: resp.data.mensagemAutomatica,
        logo: resp.data.logo,
        headerOutput: resp.data.headerOutput,
        footerOutput: resp.data.footerOutput,
        idCustom: resp.data.idCustom,
      };

      this.$store.commit("updateSaldoPrePago", resp.data.saldoPrePago);
      
      const respToken = await this.$http.get(`/v1/users/token/${this.$store.state.user.operador ?this.$store.state.user.usuario : this.$store.state.user._id}`);
      this.token = respToken.data.token;
    },
    getHtmlFrom(str) {
      if (str) {
        return str
          .replace(/(?:\*)([^*]*)(?:\*)/gm, "<strong>$1</strong>")
          .replace("{#", "<b>Contato: </b>")
          .replace("#}", "")
          .replace(/(?:_)([^_]*)(?:_)/gm, "<i>$1</i>")
          .replace(/(?:~)([^~]*)(?:~)/gm, "<strike>$1</strike>")
          .replace(/\n/gim, "<br/>")
          .replace(/(?:```)([^```]*)(?:```)/gm, "<tt>$1</tt>");
      } else {
        return str;
      }
    },
  },
  async beforeMount() {
    this.start();
  },
};
</script>
